
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

$goldleaf:#DAC7A0;
$bloody:#751616;
$white:#E4E4E4;
$paper:#FBF0DB;
$dark:#17181A;
$alice:aliceblue;
$void:black;

html{
    overflow: hidden;
}

body{
  transition: background, color, 0.25s ease-out;
  font-family: 'Lato', sans-serif;
  position: relative;
  margin: 0;
  padding: 0;

  &[data-theme='white']{
    background: #e4e4e4;
    color: black;
  }

  &[data-theme='paper']{
    background: #fbf0db;
    color: black;
  }

  &[data-theme='dark']{
    background: #17181a;
     color: aliceblue;
    .ico{ background: $alice; }

    #percentBar{
      border-color: $alice;
      span{ background: $alice; }
    }

    #playPause_container{
        border-color: $alice;
    }
  }

}

hr{
  border-color: rgb(61, 61, 61);
  width: 100%;
}

.ico{
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $dark;
  margin: 0 5px;
  position: relative;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size:contain;
  background: $dark;
  &[data-name='home']{ mask-image: url('./assets/home_icon.svg'); }
  &[data-name='settings']{ mask-image: url('./assets/settings_icon.svg'); }
  &[data-name='turtle']{ mask-image: url('./assets/turtle.svg'); }
  &[data-name='cat']{ mask-image: url('./assets/cat.svg'); }
  &[data-name='rabbit']{ mask-image: url('./assets/rabbit.svg'); }
  &[data-name='play']{ mask-image: url('./assets/play.svg'); }
  &[data-name='pause']{ mask-image: url('./assets/pause.svg'); }
}

.label{
  display: flex;
  flex-direction: row;
  align-items: center;
}

input{
  border-radius: 1.3px;
}
input[type='submit']{
  background: $goldleaf;
  border: none;
  box-sizing: border-box;
  padding: 8px 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: bold;
  cursor: pointer;
}
input[type='text']{
  &:focus{
    outline: none;
  }
      border: none;
      box-sizing: border-box;
      padding: 8px 12px;
}

button.squareButton {
    border: none;
    font-size: 20px;
    font-weight: bold;
    background: antiquewhite;
    margin: 0 5px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    .ico{
      margin: auto;
    }
    display: flex;
    align-items: center;
    justify-content: center;

}

#home{
  background: $dark;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  img{
    position: absolute;
    object-fit: cover;
    width: 100%;
  }
  #urlContent{
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    h2{
      color: $goldleaf;
      font-size: 3rem;
    }

    #maininput{
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      margin-bottom: 100px;
      input[type='text']{
        min-width: 30vw;
        margin-right: 10px;
      }
    }


  }
}


#settings{
  position: fixed;
  top: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 20px;
  max-width: 100vw;
  max-height: 100vh;
  z-index: 1;

  #settingsBar{
    display: flex;
    margin: 10px;
    position: relative;

    span{
      &.active{
        background: $white;
        &::before{
          content: '';
          position: absolute;
          background: $dark;
          border-radius: 100%;
          width: 100%;
          height: 100%;
        }
      }

    }
  }

  #settingsPanel{
    background: $dark;
    box-sizing: border-box;
    padding: 20px;
    width: 90vw;
    height: 85vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 5px rgb(15 18 28 / 20%);

    .mock_background{
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }

    #settings_list{
      position: relative;
      color: $white;
      width: 100%;
      height: 100%;

      & > .label{
        .ico{ background: $goldleaf;}
        color: $goldleaf;
        justify-content: center;
        font-size: 24px;
        margin: 10px 0;
      }
      & > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        & > section{
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;
        }

      }
      .theme_select{

        span{
          margin: 0 10px;
          border: solid white 2px;
          box-shadow: 0 3px 5px rgba(#000000, 0.20);
          width: 30px;
          height: 30px;
          display: block;
          border-radius: 100%;
          cursor: pointer;

        }
      }

    }
  }

  & > *{
    pointer-events: auto;
  }


}

#popupTranslator{

  position: fixed;
  display: inline-block;
  width: 100vw;
  height: 100vh;

  #popupPanel{
    width: 100%;
    min-height: 100vh;
    background: $dark;
    margin-top: 5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    top: 75vh;
    position: absolute;
    left: 0;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    box-shadow: 0 -4px 5px rgb(15 18 28 / 20%);
    flex-direction: column;
  }
  .holder{
    width: 70px;
    height: 8px;
    border-radius: 10em;
    background: $white;
    display: block;
    margin-top: 20px;
  }

  .content{
    width: 100%;
    color: white;
    box-sizing: border-box;
    padding: 20px;
    padding-top: 5px;

    & > section{
      box-sizing: border-box;
      padding: 10px;
      margin-bottom: 10px;
      & > p:first-of-type{
        margin: 0;
        font-size: 30px;
      }
      & > p:last-of-type{
        color:$goldleaf;
      }
    }
  }
}


#scene{
  font-size: 2.5rem;
  text-align: center;
  position: absolute;
  top:45vh;
  box-sizing: border-box;
  padding: 0 20%;

  & > .blank{
    display: block;
    width: 100%;
    height: 45vh;
  }

  p{
    opacity: 0;
    transform: scale(0.8);
    transition: transform, opacity, 0.4s ease;
    pointer-events: none;
    &.active{
      pointer-events: auto;
      opacity: 1;
      transform: scale(1);
      & ~ *{
        opacity: 0.1;
      }
    }
    &.neighbor{
      opacity: 0.3;
    }
  }

  .paragraph{
    margin-bottom: 200px;
  }
}


#playPause_container{
  position: fixed;
  z-index: 0;
  bottom: 20px;
  left: 20px;
  border-radius: 10em;
  border: solid $dark 1px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > *{
    width: 50%;
    height: 50%;
    margin: 0;
  }
}

#percentBar{
  z-index: 0;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 10px;
  height: 100px;
  border-radius: 1em;
  border: solid 1px $dark;
  overflow: hidden;

  span{
    width: 100%;
    display: block;
    height: 0%;
    background: $dark;
    transition: height 0.2s ease;
  }
}

@media only screen and (max-width: 800px) {

  #home{
    #painting{
      img{
        &[data-type='cloud']{
          &:first-of-type{ transform: translateX(-70%); }
          &:not(:first-of-type){ transform: translateX(70%) scaleX(-1); }
        }
      }
    }
  }

}


@media only screen and (max-width: 450px) {

  #scene{
    padding: 0 10%;
    top:35%;
  }

    #urlContent{
      #maininput{
        input[type='text']{ min-width: 60vw; }
      }
    }

}
